.search__form {
    margin-bottom: 25px;
    padding: 0 30px;
}

.search__bar input {
    width: 100%;
    padding: 8px 10px;
    display: block;
    border: none;
    outline: none;
    border-radius: 5px;
    box-shadow: none;
    font-size: 18px;
}

.search__results {
    padding: 0 30px;
}

.search__results__list__item {
    background-color: #222;
    padding: 10px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 15px;
    max-width: 100%;
    text-align: left;
    margin-bottom: 15px;
}

.search__results__list__item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.search__results__list__item__info {
    display: grid;
    grid-template-rows: auto auto 1fr;
}

.search__results__list__item__info__description {
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}