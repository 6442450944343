.media-trailer {
    text-align: center;
}

.btn.trailer {
    background: none;
    border: none;
    color: #2CE2D9;
    font-size: 16px;
    display: inline-flex;
    gap: 8px;
    margin: -10px auto 0;
    text-decoration: none;
}