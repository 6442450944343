.media-metadata {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 80%;
    margin: 35px auto;
    text-align: center;
}

.media-metadata-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.media-metadata-item .value {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}

.media-metadata-item .label {
    font-size: 16px;
    color: #aaa;
}