.media-page .actions {
    margin: 35px 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.media-page .actions button {
    display: block;
    border: 2px solid;
    border-radius: 5px;
    padding: 13px 15px;
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.media-page button.recommend {
    border-color: rgb(44, 226, 217);
    color: rgb(44, 226, 217);
}

.media-page button.watchlist,
.media-page button.watched {
    background-color: rgb(224, 46, 46);
    border-color: rgb(224, 46, 46);
    color: #fff;
}