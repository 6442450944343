header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    padding: 0 30px;
}

header h1,
header h2 {
    margin: 0;
}

header .login-link {
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1;
    padding: 3px 5px;
    border-radius: 5px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.5);
}