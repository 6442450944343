.media-backdrop {
    max-width: calc(100% + 30px);
    margin: -110px 0;
    margin-bottom: -35%;
    z-index: -1;
    position: relative;
}

.media-backdrop img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.media-backdrop::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: 0;
    height: 120px;
    width: 100%;
    background: linear-gradient(180deg, rgba(29, 28, 28, 0) 0%, #121212 43.7%);
}

.media-poster {
    margin-bottom: 35px;
    text-align: center;
}

.media-poster img {
    max-height: 240px;
    width: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.5);
}