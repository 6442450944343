* {
	box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212;
  color: #fff;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}

h1 {
	font-size: 24px;
	margin-bottom: 35px;
}

h2 {
	font-size: 22px;
	margin-bottom: 25px;
}

a {
  color: #fff;
}

.app-wrapper {
	min-height: calc(99vh - 155px);
	padding: 30px 0 110px;
}

nav {
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 80px;
  align-items: center;
	background-color: #121212;
	box-shadow: 0px 40px 75px 20px rgba(90, 90, 90, 0.4);
	padding-bottom: env(safe-area-inset-bottom);
}

.text-left {
	text-align: left;
}

.result_metadata .metadata_item,
.media-page .metadata_item {
	text-transform: uppercase;	
	font-weight: bold;
	color:#aaa;
}

.result_metadata .metadata_item:not(:last-of-type),
.media-page .metadata_item:not(:last-of-type) {
	margin-right: 20px;
}

.media-page .media-title {
	text-align: center;
	margin-bottom: 15px;
}

.media_list {
	padding: 0 30px;
}
.media-list-item .actions {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
	align-items: flex-end;
}

.media-list-item button {
	background-color: transparent;
	border: 2px solid;
	border-radius: 0px;
	padding: 8px;
	font-size: 13px;
	font-weight: bold;
	text-transform: uppercase;
}

.media-list-item button.recommend {
	border-color: rgb(44, 226, 217);
	color: rgb(44, 226, 217);
}

.media-list-item button.watchlist {
	border-color: rgb(224, 46, 46);
	color:rgb(224, 46, 46);
}

.media-list-trending {
	outline: none;
	overflow: hidden;
}

.media-list-block img {
	max-width: 200px;
	height: auto;
	border-radius: 8px;
}