.signup-page {
    text-align: center;
}

.signup-form {
    display: grid;
    gap: 8px;
    padding: 0 15px;
    margin-bottom: 10px;
}

.signup-form input {
    width: 100%;
    display: block;
    padding: 13px 15px;
    font-size: 16px;
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: 5px;
}

.signup-form button {
    display: block;
    border: 2px solid;
    border-radius: 5px;
    padding: 13px 15px;
    margin-top: 8px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    background-color: #1eb323;
    border-color: #1eb323;
    color: #ffffff;
}